.profile__card {
  height: 100%;
  padding: 0;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 30px 40px 30px;
  }

  .timeline__content {
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &:after {
      @include themify($themes) {
        border-left-color: themed('colorBackgroundBody');
      }
    }
  }

  .timeline .timeline__item:nth-child(even) {
    .timeline__content {
      &:after {
        @include themify($themes) {
          border-right-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  &.profile__card--calendar {
    padding: 30px 10px;
  }
}

.company_profile__information {
  padding: 30px 40px;
  display: flex;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.company__profile__avatar {
  height: 176px;
  width: 176px;
  min-width: 176px;
  min-height: 176px;
  overflow: hidden;
  border-radius: 99em;
  position: relative;
  bottom: 20px;
  background: #ffffff;
  box-shadow: 0 -2px 0 #fff;
  img {
    height: 100%;
    width: 100%;
  }
}

.company__profile__avatar_placeholder {
  height: 176px;
  width: 176px;
  min-width: 176px;
  min-height: 176px;
  overflow: hidden;
  border-radius: 99em;
  position: relative;
  bottom: 20px;
  box-shadow: 0 -2px 0 #fff;
  background-color: $color-blue;
  color: white;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company__profile__information {
  padding: 30px 40px 0px 40px;
  display: flex;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
}

.profile__information {
  padding: 20px 30px;
  display: flex;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.profile__avatar_placeholder {
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $color-blue;
  color: #ffffff;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile__avatar {
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  overflow: hidden;
  border-radius: 50%;
  img {
    height: 100%;
    width: 100%;
  }
}

.profile__data {
  padding-left: 25px;
  padding-top: 20px;
  width: calc(100% - 200px);
}

.profile_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
}
.profile__name {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
}

.profile_description {
  font-weight: 400;
  padding-bottom: 20px;
  margin-left: 40px;
  opacity: 0.8;
  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.profile_description_label {
  font-weight: 600;
  margin: 20px 40px;
}

.profile_heading {
  text-transform: uppercase;
  font-weight: 900;
  line-height: 18px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.profile__work {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
}

.profile__contact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
  &.success {
    color: #42e2b5;
  }
  &.danger {
    color: #ff4861;
  }
}

.profile__btn {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}

.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    margin-top: 10px;
    width: auto;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.profile__activity-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 64px;
  margin-right: 20px;

  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.profile__activity-name {
  font-weight: 700;
  color: $color-blue;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-additional;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  display: flex;
  margin: 0 20px;
  padding: 10px 0 10px 28px;
  position: relative;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-of-type {
    border-bottom: none;
  }

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: #ffffff;
    margin-left: 5px;

    &.badge-info {
      background-color: $color-accent;
    }

    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {
  position: absolute;
  left: 0;
}

.profile__see-all-tasks {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.comapny-avatar {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  border-radius: 100%;

  border: 3px solid #fff;

  font-size: 3rem;
  color: rgba($dark, 0.125);
}

.company-logo-display {
  display: block;
  margin: 0 auto;
  margin-bottom: $spacer;

  .company-logo-display-inner {
    position: relative;
    display: block;
    max-width: 256px;
    margin: 0 auto;
  }

  div.actions {
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: $spacer * 0.75;
    }
  }

  @include media-breakpoint-down(md) {
    .btn {
      width: 100%;
      margin-top: $spacer;
    }
  }
}

.company__avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: $color-blue;
  color: white;
}

.company_select_header {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  opacity: 0.6;
  margin-bottom: 10px;
}
.company_select_card_wrapper {
  width: 100%;
  max-height: 50vh;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  overflow-y: auto;
}
.company_select_main_wrapper {
  height: 60px;
  margin-bottom: 5px;
}

.company_select_wrapper_active {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px;
  max-width: 500px;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid #4ce1b6;
  cursor: pointer;

  &:focus,
  &:active {
    background-color: #f0f0f0;
    border: 1px solid #4ce1b6;
  }
}

.company_select_wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 60px;
  padding: 10px;
  max-width: 500px;
  width: 100%;
  // .company__select_radio {
  //   display: flex;
  //   // background-color: cadetblue;
  //   padding-left: 10px;
  //   width: calc(100% - 90%);
  //   height: 36px;
  //   align-items: center;
  //   justify-content: flex-end;
  // }

  &:focus,
  &:active {
    background-color: #f0f0f0;
    border: 1px solid #4ce1b6;
  }
}
.company_select_inner_wrapper {
  display: flex;
  align-items: center;
  width: calc(100%);
  cursor: pointer;
  // background-color: blueviolet;
  .company__select_name {
    font-weight: 500;
    opacity: 0.6;
    line-height: 18px;
    width: 80%;
  }

  .company__avatar_img {
    display: flex;
    align-items: center;
    height: 36px;
    width: 36px;
    min-width: 36px;
    min-height: 36px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #f0f0f0;

    img {
      height: 36px;
      width: 36px;
    }
  }
}
.company_edit_profile {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.employee__list_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}
