@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css');
//bootstrap
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/nav';
@import "node_modules/bootstrap/scss/utilities";
@import 'settings/variable'; //themes' colors

//vendor...
@import './vendor.scss';

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span

//components...
@import './component/btn';
@import './component/card';
@import './component/check-box';
@import './component/sidebar';
@import './component/topbar';
@import './component/load';
@import './component/form';
@import './component/material-form';
@import './component/edit-table';
@import './component/select-options';
@import './component/pagination';
@import './component/table.scss';
@import './component/dropdown.scss';
@import './component/text-editor.scss';
@import './component/date-picker.scss';
@import './component/dropzone.scss';
@import './component/panel.scss';
@import './component/tabs.scss';
@import './component/time-picker';
@import './component/material-table.scss';
@import './component/tooltip.scss';
@import './component/radio-btn.scss';
@import './component/color-picker.scss';
@import './component/wizard.scss';
@import './component/switch.scss';
@import './component/slider.scss';
@import './component/company-profile-modal';

//containers...
@import './containers/help.scss';
@import './containers/products-list.scss';
@import './containers/dashboard.scss';
@import './containers/matches.scss';
@import './containers/affiliations.scss';
@import './containers/lineup.scss';
@import './containers/app_layout.scss';
@import './containers/profile.scss';

//pages...
@import 'containers/account';
@import 'containers/boxoffice.scss';
@import 'containers/kiosk.scss';
@import 'containers/packages.scss';
@import 'containers/notfound.scss';

//objects...
@import 'objects/layout.scss';

//custom...
@import './custom.scss';

@import './Linearicons.css';
