.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;
  background-color: #fcfcfc;

  /*@include themify($themes) {
    background-color: themed('colorBackground');
  }*/
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {
  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

// .container__cover {
//   background: rgba(108, 117, 125, 0.5);
//   max-height: 30%;
//   max-width: 100%;
//   width: 100%;
//   position: relative;
//   img {
//     width:100%;
//     height:100%;
//     object-fit: cover;
//     aspect-ratio: 16/9;
//   }
// }

.container__cover {
  background: rgba(108, 117, 125, 0.5);
  max-height: 500px;
  width: calc(100% - 81px);
  margin: 0px 40px;
  display: block;
  position: relative;
  top: 40px;
  img {
    max-height: 500px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
  }
}

.container__cover_placeholder {
  background: rgba(108, 117, 125, 0.4);
  height: 500px;
  width: calc(100% - 81px);
  margin: 0px 40px;
  display: flex;
  position: relative;
  top: 40px;
  font-size: 24px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  color: #646777;
}
