.tableShowNews {
  display: flex;
  position: relative;
  top: 6px;
  text-transform: none;
}

.padding-0 {
  padding: 0 !important;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-top-3 {
  padding-top: 3px;
}
.paddingHorizontal-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.margin-0 {
  margin: 0 !important;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.marginRight-15 {
  margin-right: 15px;
}

.marginLeft-8 {
  margin-left: 8px !important;
}

.marginRight-8 {
  margin-right: 8px !important;
}
.marginVertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.height-10 {
  width: auto;
  height: 10px;
}

.height-30 {
  height: 30px;
}
.height-50 {
  height: 50px;
}

.width-100p {
  width: 100%;
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-flow: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-10 {
  flex: 10;
}

.fontSize-13 {
  font-size: 13px;
}
.fontSize-80 {
  font-size: 30px;
}
.fontSize-50 {
  font-size: 50px;
}

.marginBottom-0 {
  margin-bottom: 0 !important;
}

.marginBottom-5 {
  margin-bottom: 5px;
}

.marginBottom-10 {
  margin-bottom: 10px;
}

.marginBottom-30 {
  margin-bottom: 30px;
}

.marginTop-0 {
  margin-top: 0;
}

.marginTop-3 {
  margin-top: 3px;
}

.marginTop-5 {
  margin-top: 5px;
}

.marginTop-15 {
  margin-top: 15px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginTop-30 {
  margin-top: 30px;
}

.marginTopNegative-21 {
  margin-top: -21px;
}
.verticalAlignCenter {
  margin-top: auto;
  margin-bottom: auto;
}
.successColor {
  color: #42e2b5;
  font-size: xx-large;
}
.dangerColor {
  color: #aa2626;
}
.dashboard__table-more {
  display: flex;

  & > button {
    margin: 0;
    padding: 0 5px;
    border: none;
    background-color: transparent !important;
    margin-left: auto;

    &:before {
      display: none;
    }

    svg {
      margin: 0;
      height: 20px;
      width: 20px;
      fill: $color-additional;
      transition: all 0.3s;
    }

    &:hover,
    &:not([disabled]):not(.disabled):active,
    &:focus {
      background: transparent;

      svg {
        fill: $color-accent;
      }
    }
  }

  & > div {
    min-width: 90px;
    width: 100%;
  }

  button {
    font-size: 13px;
  }
}

.upload-files-box {
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 0 !important;
  border: 1px solid #eaeaea;
  border-radius: 0;
  padding: 0;
  margin-bottom: 30px;
}

.kiosk-upload-files-box {
  width: 100%;
  height: 100%;
  padding: 25px;
  border: 1px solid #eaeaea;
  border-radius: 0;
}

.teamLogoTable {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: cover;
}

.margin-top-0 {
  margin-top: 0;
}

.square-badge {
  width: 65px;
  padding: 6px 6px !important;
  border-radius: 0.25rem !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}

.rc-time-picker {
  width: 100%;
}

.react-datepicker-popper {
  min-width: 320px;
}

.matchesAddButton {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.overflowY-hidden {
  overflow-y: hidden;
}

.overflow-unset {
  overflow: unset;
}

span.badge-square-tags {
  font-size: 13px;
  border-radius: 4px;
  padding: 6px 10px;
  text-align: center;
  background-color: #70bbfd;
  color: white;
  font-weight: 500;
}

.outline-0 {
  outline: 0;
}

.marginBottom-20 {
  margin-bottom: 20px;
}

.alignItems-center {
  align-items: center;
}

.flex-Vcenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.flex-Hcenter {
  justify-content: center;
}

.flex-HSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  appearance: none;
}

.checkboxToggled {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  width: 18px;
  height: 18px;
  border: 1px solid white;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  appearance: none;
}

.checkboxInput {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  width: 18px;
  height: 18px;
  border: 1px solid white;
  border-radius: 4px;
  background: #4ce1b6;
  cursor: pointer;
  appearance: none;
}
.checkboxInput-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  width: 18px;
  height: 18px;
  border: 1px solid white;
  border-radius: 4px;
  background: #c7c7c7;
  cursor: pointer;
  appearance: none;
}
.checkbox:hover {
  border-color: #4ce1b6;
}

.checkbox-icon {
  position: relative;
  top: -20px;
  left: 1px;
  width: 0;
  height: 0;
}

.height-18 {
  height: 18px;
}

.pointerEvents-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.transitionDuration-03 {
  transition-duration: 0.3s;
}

.white-underline {
  border-bottom: 1px solid #d8d9db;
}

.hidden {
  visibility: hidden;
}

.noWrap {
  flex-wrap: nowrap;
}

.flex-centerVertHort {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-centerVertHort {
  display: flex;
  justify-content: flex-end;
}

a.outline-badge {
  padding: 2px 15px;
  background-color: #42e2b5;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.3s;
}

a.outline-badge:hover {
  color: #32c091;
  border-color: #42e2b5;
}

button.outline-badge.success {
  background-color: #42e2b5;
  &:hover {
    color: #32c091;
    border-color: #42e2b5;
  }
}

button.outline-badge {
  padding: 4px 15px;
  border-radius: 5px;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #ff4861;
  color: white;
  outline: 0;
  border: 0;
}

button.outline-badge:hover {
  color: #bb2d2d;
  border-color: #ff4861;
}

a.outline-badge.danger {
  background-color: #ff4861;
  padding: 2px 8px;
  font-size: 12px;
}

a.outline-badge.danger:hover {
  color: #aa2626;
}

.table-responsive {
  overflow-y: hidden;
}

.btn.headerDropdown {
  font-size: 24px;
  background: none;
  border: none;
}

.btn.headerDropdown:hover {
  background: none;
  border: none;
}

.btn.headerDropdown::before {
  background: none;
  border: none;
}

.btn.headerDropdown:active {
  background: none;
  border: none;
}

.tabs.tabs--justify ul.nav li.width-unset {
  width: unset;
}

.fontWeight-700 {
  font-weight: 700;
}

.fontWeight-900 {
  font-weight: 900;
}

.fontSize-24 {
  font-size: 24px;
}

.checkbox-position {
  display: flex;
  align-items: center;
  height: 40px;
}

.tabSmallText {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
  height: 50px;
}

.pagination__link.pagination-selected {
  color: #646779;
  font-weight: bold;
}

.transition-0 {
  transition: all 0s;
}

.color-unset {
  color: unset;
}

.color-unset:hover {
  color: unset;
}

button.dropdown-item.danger {
  color: red;
}

.width-100vw {
  width: 100vw;
}

.height-100vh {
  height: 100vw;
}

.loading-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 100vw;
  bottom: 100vh;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    z-index: 2001;
    animation: refresh 1s infinite linear;
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }

  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.contorlImage {
  padding: 0;
  outline: 1px solid #e7e7e7;
}

.form__form-group-input-wrap {
  margin-bottom: 5px;
}

.ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row.listSelected {
  background-color: #42e2b5;
  border-bottom: 1px solid white;
  color: white;
  border-color: white;
  transition-duration: 0.3s;
}

#root .theme-light .listDisabled {
  background-color: #c7c7c7;
}

#root .theme-light .listSelected {
  background-color: #42e2b5;
  border-bottom: 1px solid white;
  color: white;
  border-color: white;
  transition-duration: 0.3s;
  td {
    color: white;
  }
  svg {
    fill: white;
  }
  .outline-badge {
    background-color: white;
    color: #42e2b5;
  }
}

button svg.dotsHorizontalIcon {
  fill: #999999;
}

button svg.dotsHorizontalIconActive {
  fill: white;
}

.btn-group button.reportsButton {
  border: 1px solid #e7e2e2;
  padding: 10px 40px;
}

.btn-group button.reportsButton:hover {
  border: 1px solid #42e2b4;
  padding: 10px 40px;
}

.dashboard__trend-icon.danger {
  fill: #ff4861;
}

.card-body.no-shadow {
  box-shadow: 0 0 0 0;
}

p.form-header {
  margin-bottom: 5px;
}

.gender-horizontal {
  display: flex;
  margin-left: 10px;
  width: 140px;
}

.kiosk-logo {
  height: 30px;
  width: auto;
}

.radio-horizontal {
  display: flex;
  width: 300px;
}

.is-disabled {
  background: #f2f4f7 !important;
}

.field-type-color {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.25);
}

div.vertical-alignMiddle {
  vertical-align: middle;
}

.table td {
  vertical-align: middle;
}

.recharts-label {
  word-break: break-all;
  fill: #646777;
  width: 20px;
}

.dropdown__menu {
  width: fit-content;
  max-height: 505px;
  overflow-y: scroll;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid:focus {
  outline: none !important;
}

.icon-min-20 {
  min-width: 20px;
  min-height: 20px;
}

.ReactVirtualized__Table__row:nth-child(odd) {
  background-color: #f6f8fa;
}

.ReactVirtualized__Table__row:nth-child(even) {
  background-color: #ffffff;
}

.table-header {
  font-weight: initial;
  text-transform: initial;
  justify-content: center;
}

.ReactVirtualized__Table__headerRow {
  border-bottom: 2px solid #d8d9db;
}

.no-style,
.btn.no-style {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  &:before {
    background-color: transparent;
  }
}

.btn.vertical-pad {
  padding: 10px 10px;
}

.popover-links {
  padding: 10px 0;
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  button {
    width: 100%;
    height: 30px;
  }
}

.popover-body {
  padding: 0;
}

.tabs a.nav-link.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.media-input {
  display: flex;
  align-items: center;
  .disabled-input {
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    border-right: none;
    height: 32px;
    margin-bottom: 5px;
    white-space: nowrap;
    background-color: #f2f4f7;

    .disabled-text {
      margin: 8px;
      color: #646777;
      font-size: 12px;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
    background-color: white;
    border-radius: 5px;
  }
  .modal-icon {
    margin-top: 10px;
    flex: 1;
  }
  .modal-text {
    margin-top: 5px;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 80%;
    h3 {
      text-align: center;
    }
  }
  .modal-buttons {
    margin-bottom: 20px;
  }
}

.presale-seasonticket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: #70bbfd;
  border-radius: 5px;
  p {
    margin: 2px;
    color: white;
  }
}

p.danger {
  color: red;
}

.ReactVirtualized__Table__row {
  overflow: visible !important;
}

.overflow-visible-hard {
  overflow: visible !important;
}

.modal-content {
  border: none;
}

.text-editor {
  width: 100%;
}

.demo-editor {
  .public-DraftStyleDefault-block {
    margin-top: 0;
    span:hover {
      cursor: text;
    }
  }
}
.pull-right {
  float: right;
}
.dropdown__menu.tickets {
  transform: translate3d(-10px, 20px, 0px) !important;
}

.login-field-country-code {
  width: 50px;
  margin-right: 10px;
}

table {
  td {
    img {
      width: auto;
      height: 24px;
    }
  }
}

.checkbox-colored-click.active {
  input {
    background-color: #4ce1b5;
    border: none;
  }
  svg {
    fill: white;
  }
}

.checkbox-colored-click {
  input {
    transition-duration: 0.3s;
    background-color: #ffffff;
  }
}

.stadium-seat:hover {
  background-color: #5c7fc2;
  color: white;
}

.stadium-seat-selected.stadium-seat {
  background-color: #70bbfd;
  color: white;
}

.stadium-seat {
  user-select: none;
  color: white;
  margin: 3px;
  height: 40px;
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: Center;
  border-radius: 5px;
  outline: none;
  border: none;
}

.stadium-seat-deactive {
  background-color: #4ce179;
}

.stadium-row {
  padding: 20px 10px;
  border-top: 1px solid #d8d9db;
}

.stadium-seat-available {
  background-color: #4ce179;
}

.stadium-seat-deactivated {
  color: #646676;
  background-color: #e7e2e2;
}

.stadium-seat-nonexistent {
  color: white;
  background-color: rgba(128, 128, 128, 0.5);
}

.center-list {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;

    div {
      vertical-align: middle;
      p {
        vertical-align: middle;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.rdw-editor-toolbar img {
  width: 12px;
}

.table thead tr th {
  border-bottom: 1px solid #d8d9db !important;
}

.textTransform-capitalize {
  text-transform: capitalize;
}

.width-fitContent {
  width: fit-content;
}

.old-panel-button {
  margin: 6px 0;
}

.full-width {
  width: 100%;
}

.center-text {
  text-align: center;
}

.icon-24 {
  width: 24px !important;
  height: 24px !important;
}

.tooltip-black {
  background-color: black !important;
  color: white;
}

.textAlign-center {
  text-align: center;
}

img.company-logo {
  max-height: 176px;
  max-width: 176px;
  height: 176px;
  width: 176px;
  border-radius: 99em;
  border: solid lightgray 2px;
}

img.company-cover {
  height: 300px;
  width: 533px;
  border: solid lightgray 2px;
}
img.employee-image {
  height: 300px;
  width: 300px;
  border: solid lightgray 2px;
  border-radius: 99em;
}

img.sponsor-app {
  max-height: 80px;
  max-width: 400px;
  width: auto;
  height: auto;
  background-color: black;
}

img.sponsor-app-ticket {
  max-height: 80px;
  max-width: 400px;
  background-color: white;
}

img.sponsor-print {
  width: 567px;
  height: 54px;
  object-fit: cover;
  margin: 20px;
  margin-left: 0;
  margin-top: 0;
  background-color: white;
}
img.app-sponsor-logo {
  width: 600px;
  height: 150px;
  margin: 20px;
  margin-left: 0;
  margin-top: 0;
  background-color: white;
}
.dagsvip-before-after {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-weight: bold;
  }
}

.sponsor-player {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  max-height: 80px;
  max-width: 400px;
  img {
    max-height: 35px;
    width: auto;
    height: auto;
  }
}

.Select-menu-outer {
  z-index: 2;
}

th.sortable {
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

th {
  font-weight: 500;
}

.rbdnd-dragging {
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  td {
    flex: 1;
  }
}

.sponsor-list-image {
  border: 1px solid black;
  max-width: 250px;
  max-height: 140px;
  object-fit: contain;
}

button.outline-badge.secondary {
  background-color: #e7e2e2;
  color: #8e8e8e;

  &:hover {
    background-color: rgb(155, 155, 155);
    color: #3c3f4d;
  }
}

button.outline-badge.primary {
  background-color: #71bbfe;

  &:hover {
    background-color: #3fa3fc;
    color: #4275c7;
  }
}

table td.image {
  vertical-align: middle;
  padding: 0;
  img {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
}

.textTransform-capitalize {
  text-transform: capitalize;
}

.row-spacing {
  margin-top: 5px;
  margin-bottom: 5px;
}

p.line-both-sides {
  position: relative;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
  color: #767987;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.line-both-sides:before,
.line-both-sides:after {
  position: absolute;
  top: 45%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: #767987;
}
.line-both-sides:before {
  margin-left: -50%;
  text-align: right;
}

.min-width-150 {
  min-width: 150px;
}
.pointerButton {
  cursor: pointer;
}
.activeUserStatsOuterContainer {
  padding-left: 150px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeUserStatsRowContainer {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
}
.activeUserStatsInnerContainer {
  border-color: #999999;
  border-style: solid;
  border-width: 1px;
  border-top: 0;
  border-bottom: 0;
  padding-left: 25px;
  padding-right: 70px;
  &.left {
    border-right: 0;
  }
}
.activeUserStatsInnerPercentage {
  font-size: 15px;
  &.danger {
    color: #ff4861;
  }
  &.success {
    color: #42e2b5;
  }
}
.triangleIcon {
  height: 10px;
  &.danger {
    fill: #ff4861;
    transform: rotate(180deg);
  }
  &.success {
    fill: #42e2b5;
  }
}
.activeUserStatsIconContainer {
  align-items: center;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.p-lr-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.cropper {
  background: white;
  width: 50%;
  height: 50%;
}

.cropper-black {
  background: black;
  width: 50%;
  height: 50%;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.loginpages-title {
  font-weight: 500;
}

.blue-button {
  background: #4181FF !important;
  color: white !important;

  p {
    color: white !important;
    svg {
      fill: white !important;
    }
  }

  &:hover, &:focus {
    background: #3467CC !important;
  }
}

.green-button {
  background: #13AF7B !important;
  color: white !important;

  p {
    color: white !important;
    svg {
      fill: white !important;
    }
  }

  &:hover, &:focus {
    background: #0F8C62 !important;
  }
}

.font-16 {
  font-size: 16px;
}

.action-btn {
  a {
    color: #646777;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 7px;
  border-radius: 3px;
  border: 1px solid #e8e5e5;
  transition: all .2s;
  &__danger {
    color: #ec2f2f;
  }
  &:hover {
    background: #f7f4f4;
  }
  button {
    outline: none;
    border: none;
    background: transparent;
  }
}

.alert-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

.modal-icon {
  margin-top: 10px;
  flex: 1;
}
.mapLink{
  border-width:1px;
  border-color:#efefef;
  padding: 5px;
  border-radius: 20px;
  border-style: solid;
  background-color: transparent;
}
.initials-image {
  width: 100%;
  height: 30em;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #CCCC;
  p {
    font-size:x-large;
    font-weight:600;
    color: #fff;
    text-align: center;
  }
}
.employee-profile-image{
  width: 40px;
  height: 40px;
  border-radius: 25px;
  p {
    font-size:medium;
    font-weight:600;
    color: #fff;
    text-align: center;
  }
}