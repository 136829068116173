.comCol {
    min-height: 312px;
    border-radius: 3%;
    flex-direction: column;
    font-weight: medium;
  }
  .templateImg {
    width: 250px;
    border-radius: 2%;
    flex-direction: column;
    object-fit: contain;
    margin-top: 42px;
    margin-right: 16px;
    &.grayBg{
      background-color: lightgray;
      height: 312px;
    }
  }
  .layoutRows {
    justify-content: center;
  }
  .rowDistance {
    padding-top: 20px;
  }
